import { z } from "zod";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";

const commandCenterMessageSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    body: z.string(),
    type: z.enum(["incoming", "outgoing"]),
    sent_timestamp: z.string().datetime().optional(),
    scheduled_timestamp: z.string().datetime().optional(),
    thread: z.any().optional(),
});

const commandCenterMessageCreateDto = z.object({
    body: z.string(),
    type: z.enum(["incoming", "outgoing"]),
    scheduled_timestamp: z.string().datetime().optional(),
    thread: z.number().int().min(1),
});

export type CommandCenterMessage = z.infer<typeof commandCenterMessageSchema>;
export type CreateCommandCenterMessageDto = z.infer<typeof commandCenterMessageCreateDto>;

const ENDPOINT = "command-center-messages";
export const commandCenterMessagesRouter = createRouter(ENDPOINT);

export const commandCenterMessages = {
    create: commandCenterMessagesRouter.mutation<CreateCommandCenterMessageDto, CommandCenterMessage>(
        async (options) => {
            const parsed = commandCenterMessageCreateDto.parse(options?.input);
            const client = await getApiClient().withAuth();
            const { data } = await client.post<RawApiResponse<CommandCenterMessage>>(ENDPOINT, { data: parsed });
            return data;
        },
    ),
} as const;
