import { z } from "zod";

export const integrationSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    key: z.string(),
    configuration: z.any().optional(),
    enable_time: z.string().datetime().optional(),
    state: z.any().optional(),
    user: z.any().optional(),
    enabled: z.boolean(),
});

export const integrationCreateDtoSchema = z.object({
    key: z.string(),
    configuration: z.any().optional(),
    enable_time: z.string().datetime().optional(),
    state: z.any().optional(),
    user: z.any().optional(),
    enabled: z.boolean(),
    customer: z.number().int().min(1),
});

export const integrationUpdateDtoSchema = integrationCreateDtoSchema.partial();

export type Integration = z.infer<typeof integrationSchema>;
export type IntegrationCreateDto = z.infer<typeof integrationCreateDtoSchema>;
export type IntegrationUpdateDto = z.infer<typeof integrationUpdateDtoSchema>;
