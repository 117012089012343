import { z } from "zod";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";

const deviceModelSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().date(),
    updatedAt: z.string().date(),
    name: z.string(),
});

export type DeviceModel = z.infer<typeof deviceModelSchema>;

const ENDPOINT = "unit-models";
export const deviceModelsRouter = createRouter(ENDPOINT);

export const deviceModels = {
    list: deviceModelsRouter.query<DeviceModel[]>(async (options) => {
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<DeviceModel[]>>(ENDPOINT, { params: options?.params });
        return data;
    }),
};
