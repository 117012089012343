import { z } from "zod";

export const domainSchema = z.object({
    domain: z.string().min(1),
});

export const customerSchema = z.object({
    id: z.number().int().min(1),
    name: z.string().min(1),
    vat_number: z.string(),
    street: z.string(),
    zip: z.string(),
    city: z.string(),
    country: z.string(),
    contact_name: z.string(),
    contact_phone: z.string(),
    contact_email: z.string(),
    data_termination_days: z.number(),
    createdAt: z.string().date(),
    updatedAt: z.string().date(),
    platform_name: z.string(),
    platform_primary_color: z.string(),
    fleet_price: z.number(),
    security_price: z.number(),
    beacon_price: z.number(),
    platform_url: z.string(),
    has_customized_appearance: z.boolean(),
    users: z.object({ data: z.array(z.any()) }).optional(),
    units: z.object({ data: z.array(z.any()) }).optional(),
    beacons: z.object({ data: z.array(z.any()) }).optional(),
    user_groups: z.object({ data: z.array(z.any()) }).optional(),
    groups: z.object({ data: z.array(z.any()) }).optional(),
    tags: z.object({ data: z.array(z.any()) }).optional(),
    reports: z.object({ data: z.array(z.any()) }).optional(),
    cases: z.object({ data: z.array(z.any()) }).optional(),
    people: z.object({ data: z.array(z.any()) }).optional(),
    integrations: z.object({ data: z.array(z.any()) }).optional(),
    service_plans: z.object({ data: z.array(z.any()) }).optional(),
    service_records: z.object({ data: z.array(z.any()) }).optional(),
    assets: z.object({ data: z.array(z.any()) }).optional(),
    platform_logo: z.any().optional(),
});

export const customerAppearanceSchema = z.object({
    name: z.string(),
    url: z.string(),
    primaryColor: z.string(),
    logoUrl: z.string(),
    hasCustomizedAppearance: z.boolean(),
});

export type Customer = z.infer<typeof customerSchema>;
export type CustomerAppearance = z.infer<typeof customerAppearanceSchema>;
