import { z } from "zod";

export const meSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    username: z.string(),
    email: z.string(),
    confirmed: z.boolean(),
    blocked: z.boolean(),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    phone: z.string().optional(),
    phone_prefix: z.string().optional(),
    simulate_customer_id: z.number().int().optional(),
    locale: z.string(),
    admin: z.boolean(),
    collapsed_sidebar: z.boolean(),
    user_group: z
        .object({
            id: z.number().int(),
            permissions: z
                .array(
                    z.object({
                        id: z.number().int(),
                        object: z.string().optional(),
                        create: z.boolean(),
                        read: z.boolean(),
                        update: z.boolean(),
                        delete: z.boolean(),
                    }),
                )
                .optional(),
        })
        .optional(),
    customer: z.object({ id: z.number().int().min(1) }).optional(),
});

export type Me = z.infer<typeof meSchema>;
