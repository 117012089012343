import { idSchema } from "~/app/api";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";
import { Report, ReportCreateDto, reportCreateDtoSchema } from "./schemas";

const ENDPOINT = "/reports";
const reportsRouter = createRouter(ENDPOINT);

export const reports = {
    one: reportsRouter.query<Report>(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<Report>>(`${ENDPOINT}/${parsedId.id}`, {
            params: options?.params,
        });
        return data;
    }),
    create: reportsRouter.mutation<ReportCreateDto, Report>(async (options) => {
        const parsedInput = reportCreateDtoSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.post<RawApiResponse<Report>>(ENDPOINT, { data: parsedInput });
        return data;
    }),
};
