import { useContext } from "react";
import { UserContext } from "~/context/User.context";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { CustomerEntity } from "~/generated-types";
import { CustomersQueryKeys } from "~/types/customers.types";
import { getCustomerById } from "~/app/api/customers-api";
import { useSession } from "next-auth/react";
import useGeneralErrorToast from "~/hooks/useGeneralErrorToast";
import { ID } from "~/types/general.types";

interface Result {
    currentCustomer: { data: CustomerEntity } | undefined;
    isCurrentCustomerLoading: boolean;
    customerError: Error | null;
}

// If there's no customerId in params of the hook, it will fetch the customer data for the current user
export const useCustomer = (customerId?: ID): Result => {
    const {
        state: { data: userData },
    } = useContext(UserContext);
    const currentCustomerId = userData.simulate_customer_id || userData.customerId;
    const session = useSession();
    const sessionJwt = session.data?.jwt as string;
    const {
        data: currentCustomer,
        isLoading: isCurrentCustomerLoading,
        error: customerError,
    } = useReactQuery<{ data: CustomerEntity }>({
        queryKey: [CustomersQueryKeys.CUSTOMER, customerId ?? currentCustomerId],
        queryFn: () => getCustomerById(customerId ?? currentCustomerId, sessionJwt),
        enabled: Boolean(customerId) || Boolean(currentCustomerId),
    });
    useGeneralErrorToast(customerError);

    return {
        isCurrentCustomerLoading,
        currentCustomer,
        customerError,
    };
};
