import { Button } from "@ui/button";
import { RegularTooltip } from "@ui/tooltip";
import { Building, LucideIcon, RadioTower, Users } from "lucide-react";
import { MessageKeys, useTranslations } from "next-intl";
import { FC } from "react";
import { Link, usePathname } from "~/i18n/routing";
import { cn } from "~/utils/lib.utils";

interface LinkItem {
    href: string;
    labelKey: MessageKeys<IntlMessages, any>;
    icon: LucideIcon;
}

export const links: LinkItem[] = [
    // { href: "/command-center", labelKey: "commandCenter", icon: TerminalIcon },
    { href: "/telenor", labelKey: "telenor", icon: RadioTower },
    { href: "/settings/customers", labelKey: "customers", icon: Building },
    { href: "/settings/users", labelKey: "users", icon: Users },
];

export const ToolbarLinks: FC<{ className?: string }> = ({ className = "" }) => {
    const t = useTranslations();
    const pathname = usePathname();
    const isActive = (href: string) => pathname.toLowerCase().includes(href.toLowerCase());

    return (
        <div className={cn("space-x-1.5", className)}>
            {links.map((link) => (
                <RegularTooltip text={t(link.labelKey)} key={link.href}>
                    <Button asChild variant={isActive(link.href) ? "default" : "ghost"} size="icon">
                        <Link href={link.href}>
                            <link.icon className="h-4 w-4" />
                        </Link>
                    </Button>
                </RegularTooltip>
            ))}
        </div>
    );
};
