/* eslint-disable react-hooks/rules-of-hooks */
import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DEFAULT_QUERY_OPTIONS } from "~/lib/api/constants";
import { ApiResponse, MutationOptions, QueryOptions, RawApiResponse } from "~/lib/api/types";
import { getQueryClient } from "~/utils/query-client.utils";

interface QueryProcedureOptions<TOutput> {
    procedurePath: string[];
    fn: (options?: QueryOptions) => Promise<TOutput>;
}

interface MutationProcedureOptions<TInput, TRawOutput, TOutput> {
    procedurePath: string[];
    fn: (options?: MutationOptions<TInput, TOutput>) => Promise<TRawOutput>;
}

class QueryProcedure<TRawOutput, TOutput = TRawOutput> {
    private readonly path: string[];
    private readonly queryFn: (options?: QueryOptions) => Promise<TRawOutput>;
    private readonly transformer?: (data: TRawOutput, keepNestedData?: boolean) => TOutput;

    constructor(opts: QueryProcedureOptions<TRawOutput> & { transformer?: (data: TRawOutput) => TOutput }) {
        this.path = opts.procedurePath;
        this.queryFn = opts.fn;
        this.transformer = opts.transformer;
    }

    private transformResponse(data: TRawOutput, keepNestedData?: boolean): TOutput {
        return this.transformer ? this.transformer(data, keepNestedData) : (data as unknown as TOutput);
    }

    async query(options?: QueryOptions): Promise<TOutput> {
        const queryClient = getQueryClient();
        const response = (await queryClient.fetchQuery({
            queryKey: this.getQueryKey(options?.params),
            queryFn: () => this.queryFn(options),
        })) as TRawOutput;
        return this.transformResponse(response, options?.keepNestedData);
    }

    useQuery(options?: QueryOptions) {
        return useQuery<QueryOptions["options"], Error, TOutput>({
            ...DEFAULT_QUERY_OPTIONS,
            queryKey: this.getQueryKey(options?.params),
            // @ts-ignore
            queryFn: () => this.query(options),
            ...options?.options,
        });
    }

    getQueryKey(params?: QueryOptions["params"]): QueryKey {
        return params === undefined ? this.path : [...this.path, params];
    }
}

class MutationProcedure<TInput, TRawOutput, TOutput> {
    private readonly path: string[];
    private readonly mutationFn: (options?: MutationOptions<TInput, TOutput>) => Promise<TRawOutput>;
    private readonly transformer?: (data: TRawOutput) => TOutput;

    constructor(
        opts: MutationProcedureOptions<TInput, TRawOutput, TOutput> & {
            transformer?: (data: TRawOutput) => TOutput;
        },
    ) {
        this.path = opts.procedurePath;
        this.mutationFn = opts.fn;
        this.transformer = opts.transformer;
    }

    private transformResponse(data: TRawOutput): TOutput {
        return this.transformer ? this.transformer(data) : (data as unknown as TOutput);
    }

    async mutate(options?: MutationOptions<TInput, TOutput>): Promise<TOutput> {
        const response = await this.mutationFn(options);
        return this.transformResponse(response);
    }

    useMutation(options?: MutationOptions<TInput, TOutput>) {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: (input: TInput) => this.mutate({ input }),
            onSuccess: () => {
                // By default, invalidate the parent path
                queryClient.invalidateQueries({ queryKey: this.path.slice(0, -1) });
            },
            ...options?.options,
        });
    }
}

const defaultStrapiTransformer = (data: any, keepNestedData?: boolean): any => {
    // If "keepNestedData" is passed as true, return the initial data with nested
    if (keepNestedData) {
        return data;
    }

    // If data is an object with id and attributes, treat as entity
    if (data?.id && data?.attributes) {
        const attributes = { ...(data.attributes ?? {}) };

        for (const key in attributes) {
            attributes[key] = defaultStrapiTransformer(attributes[key]);
        }

        return {
            id: data.id,
            ...attributes,
        };
    }

    // If nested data is an array, map the items to transform the collection
    if (Array.isArray(data?.data)) {
        const transformedData = data.data.map((datum: any) => defaultStrapiTransformer(datum));
        return {
            data: transformedData,
            meta: data.meta,
        };
    }

    // If data has nested data, treat as nested entity (e.g., relations)
    if (data?.data) {
        return { data: defaultStrapiTransformer(data.data) };
    }

    // Fallback to returning data as-is
    return data;
};

class Router {
    private readonly basePath: string[];

    constructor(basePath: string[] = []) {
        this.basePath = basePath;
    }

    query<T>(fn: (options?: QueryOptions) => Promise<RawApiResponse<T>>) {
        return new QueryProcedure<RawApiResponse<T>, ApiResponse<T>>({
            procedurePath: this.basePath,
            fn,
            transformer: defaultStrapiTransformer,
        });
    }

    mutation<TInput, TOutput>(fn: (options?: MutationOptions<TInput, TOutput>) => Promise<RawApiResponse<TOutput>>) {
        return new MutationProcedure<TInput, RawApiResponse<TOutput>, TOutput>({
            procedurePath: this.basePath,
            fn,
            transformer: defaultStrapiTransformer,
        });
    }
}

export function createRouter(path: string) {
    return new Router([path]);
}
