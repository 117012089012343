import { ID } from "~/types/general.types";

export interface BasicCustomerData {
    id: ID;
    attributes: {
        name: string;
    };
}

export interface BasicCustomersListData {
    data: BasicCustomerData[];
}

export interface CustomerPublicAppearanceData {
    name: string;
    url: string;
    primaryColor: string;
    logoUrl: string;
    hasCustomizedAppearance: boolean;
}

export enum CustomersQueryKeys {
    CUSTOMER = "customer",
    BASIC_CUSTOMERS_LIST = "basic_customers_list",
}
