import { idSchema } from "~/app/api";
import {
    Integration,
    IntegrationCreateDto,
    integrationCreateDtoSchema,
    IntegrationUpdateDto,
    integrationUpdateDtoSchema,
} from "~/app/api/integrations/schemas";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";

const ENDPOINT = "/integrations";
const integrationsRouter = createRouter(ENDPOINT);

export const integrations = {
    list: integrationsRouter.query<Integration[]>(async (options) => {
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<Integration[]>>(ENDPOINT, { params: options?.params });
        return data;
    }),
    create: integrationsRouter.mutation<IntegrationCreateDto, Integration>(async (options) => {
        const parsedInput = integrationCreateDtoSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.post<RawApiResponse<Integration>>(ENDPOINT, { data: parsedInput });
        return data;
    }),
    update: integrationsRouter.mutation<IntegrationUpdateDto & { id: number }, Integration>(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const parsedInput = integrationUpdateDtoSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.put<RawApiResponse<Integration>>(`${ENDPOINT}/${parsedId.id}`, {
            data: parsedInput,
        });
        return data;
    }),
};
