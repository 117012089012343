import { Me } from "~/app/api/users/schemas";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";

const ENDPOINT = "/users";
const usersRouter = createRouter(ENDPOINT);

export const users = {
    me: usersRouter.query<Me>(
        async (
            options = {
                params: {
                    populate: {
                        customer: { fields: ["id"] },
                        user_group: { populate: "*" },
                        avatar: true,
                    },
                },
            },
        ) => {
            const client = await getApiClient().withAuth();
            const { data } = await client.get<Me>(`${ENDPOINT}/me`, {
                params: options?.params,
            });
            return { data } as unknown as RawApiResponse<Me>;
        },
    ),
} as const;
