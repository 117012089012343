"use client";

import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Expand, GripVertical, Shrink } from "lucide-react";
import Draggable, { ControlPosition, DraggableBounds, DraggableData } from "react-draggable";
import { Button } from "@ui/button";
import { ToolbarLinks } from "~/components/admin/toolbar/toolbar-links";
import { CustomersDropdown } from "~/components/customer/simulation/customers-dropdown";
import { cn } from "~/utils/lib.utils";

const BOUNDS_SPACE_SIZE = 10;
const IS_COLLAPSED_KEY = "safir-admin-toolbar-is-collapsed";
const IS_LOCKED_POSITION = "safir-admin-toolbar-is-locked";
const DRAGGABLE_POSITION_KEY = "safir-admin-toolbar-position";

export const AdminWebToolbar: FC = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const [prevRightPosition, setPrevRightPosition] = useState(0);
    const [bounds, setBounds] = useState<DraggableBounds>({});
    const [isHighlightedPosition, setIsHighlightedPosition] = useState(false);
    const [isReadyToLock, setIsReadyToLock] = useState(false);
    const [initialPosition, setInitialPosition] = useState<ControlPosition>({} as ControlPosition);
    const [position, setPosition] = useState<ControlPosition>(initialPosition);
    const [isLocked, setIsLocked] = useState(() => {
        const value = localStorage.getItem(IS_LOCKED_POSITION);

        return value ? JSON.parse(value) : false;
    });
    const [isWindowResized, setIsWindowResized] = useState(false);
    const [isLockedToRight, setIsLockedToRight] = useState(false);

    const savedState = useMemo(() => {
        const savedPosition: ControlPosition = JSON.parse(localStorage.getItem(DRAGGABLE_POSITION_KEY) as string);
        const savedIsCollapsed = localStorage.getItem(IS_COLLAPSED_KEY) === "true";

        return { savedPosition, savedIsCollapsed };
    }, []);

    const calculateInitialPosition = (): ControlPosition => {
        let initial = { x: window.innerWidth / 2 - 100, y: window.innerHeight - 70 };
        const toolbarEl = toolbarRef.current;

        if (toolbarEl) {
            const halfToolbarEl = toolbarEl.offsetWidth / 2;

            initial.x = window.innerWidth / 2 - halfToolbarEl;
        }

        return initial;
    };

    useEffect(() => {
        // Set a position if a screen width was resized
        if (isWindowResized) {
            setPrevRightPosition(0);

            if (toolbarRef.current) {
                const initial = calculateInitialPosition();
                setInitialPosition(initial);

                const toolbarData = toolbarRef.current?.getBoundingClientRect();
                const isToolbarOutsideX = window.innerWidth <= toolbarData?.right;
                const isToolbarOutsideY = window.innerHeight <= toolbarData.bottom;

                if (isLocked) {
                    handlePosition(initial);
                } else {
                    const newPosition = { x: position.x, y: position.y };

                    if (isToolbarOutsideX || isLockedToRight) {
                        newPosition.x = window.innerWidth - toolbarData.width - BOUNDS_SPACE_SIZE;
                        setIsLockedToRight(true);
                    }
                    if (isToolbarOutsideY) {
                        newPosition.y = window.innerHeight - toolbarData.height - BOUNDS_SPACE_SIZE;
                    }

                    handlePosition(newPosition);
                }
            }

            setIsWindowResized(false);
        }
    }, [isWindowResized]);

    const lockToRightOnInitial = () => {
        if (toolbarRef.current) {
            const { savedPosition } = savedState;

            // Checking if the toolbar was pinned to the right side
            if (toolbarRef.current?.offsetWidth + savedPosition.x === window.innerWidth - BOUNDS_SPACE_SIZE) {
                setIsLockedToRight(true);
            }
        }
    };

    useEffect(() => {
        const { savedPosition } = savedState;

        const initial = calculateInitialPosition();
        setInitialPosition(initial);

        if (savedPosition) {
            lockToRightOnInitial();

            return handlePosition(savedPosition);
        }

        handlePosition(initial);
    }, [toolbarRef, isCollapsed, savedState]);

    const adjustLockedState = (isLocked: boolean) => {
        setIsLocked(isLocked);
        localStorage.setItem(IS_LOCKED_POSITION, `${isLocked}`);
    };

    const resetPositionToInitial = () => {
        setPosition(initialPosition);
        adjustLockedState(true);
    };

    const handleResetPositionOnMount = () => {
        const savedPosition = JSON.parse(localStorage.getItem(DRAGGABLE_POSITION_KEY)!);

        if (!savedPosition) return;
        const isToolbarOutsideX = window.innerWidth <= savedPosition.x;
        const isToolbarOutsideY = window.innerHeight <= savedPosition.y;

        if (isToolbarOutsideX || isToolbarOutsideY) {
            resetPositionToInitial();
        }
    };

    useEffect(() => {
        handleResetPositionOnMount();

        let resizeTimeout: NodeJS.Timeout;

        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                setIsWindowResized(true);
            }, 300);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setIsCollapsed(savedState.savedIsCollapsed);
    }, [savedState]);

    const lockToRightOnDrag = (data: DraggableData) => {
        if (data.node) {
            const elementData = data.node.getBoundingClientRect();

            if (elementData.right === window.innerWidth - BOUNDS_SPACE_SIZE) {
                setIsLockedToRight(true);
            } else {
                setIsLockedToRight(false);
            }
        }
    };

    const handlePosition = (data: DraggableData | ControlPosition) => {
        const { x, y } = data;
        let newPosition = { x, y };

        if (isReadyToLock) {
            newPosition = initialPosition;
            adjustLockedState(true);
            setIsReadyToLock(false);
        }

        setPosition(newPosition);
        setIsHighlightedPosition(false);
        localStorage.setItem(DRAGGABLE_POSITION_KEY, JSON.stringify(newPosition));

        lockToRightOnDrag(data as DraggableData);
    };

    const handleCollapsing = () => {
        const elementData = toolbarRef.current?.getBoundingClientRect();

        if (elementData) {
            setPrevRightPosition(elementData.right);
        }

        setIsCollapsed((prevState) => {
            const newValue = !prevState;
            localStorage.setItem(IS_COLLAPSED_KEY, `${newValue}`);

            return newValue;
        });
    };

    useEffect(() => {
        // It aligns the toolbar to the left edge
        if (position.x < BOUNDS_SPACE_SIZE) {
            handlePosition({ x: BOUNDS_SPACE_SIZE, y: position.y });
        }
    }, [position, isCollapsed]);

    useEffect(() => {
        if (isLocked) {
            return handlePosition(initialPosition);
        }
        // It keeps the position of toolbar by right edge on collapsing the toolbar
        if (prevRightPosition && toolbarRef.current) {
            handlePosition({ x: prevRightPosition - toolbarRef.current.offsetWidth, y: position.y });
        }
    }, [isCollapsed, initialPosition]);

    const handleStart = (data: DraggableData) => {
        if (!data) return;

        setIsHighlightedPosition(true);
        adjustLockedState(false);
        setBounds({
            left: BOUNDS_SPACE_SIZE,
            right: window.innerWidth - data.node.offsetWidth - BOUNDS_SPACE_SIZE,
            top: BOUNDS_SPACE_SIZE,
            bottom: window.innerHeight - data.node.offsetHeight - BOUNDS_SPACE_SIZE,
        });
    };

    const handleOnDrag = (data: DraggableData) => {
        const elementData = data.node.getBoundingClientRect();
        const isTopClose = initialPosition.y - elementData.bottom <= 50;
        const isLeftClose =
            initialPosition.x - elementData.right <= 50 &&
            initialPosition.x - elementData.right + elementData.width >= 0;
        const isRightClose =
            elementData.left >= initialPosition.x && initialPosition.x + elementData.width - elementData.left >= -50;

        if (isTopClose && (isLeftClose || isRightClose)) {
            return setIsReadyToLock(true);
        }

        setIsReadyToLock(false);
    };

    const renderHighlightedPosition = () => (
        <div
            className={cn(
                "fixed z-admin-toolbar-position h-40 w-40 rounded-xl bg-gray-300 text-white opacity-50 shadow transition duration-200",
                !isHighlightedPosition && "hidden",
                isReadyToLock && "bg-gray-400 opacity-60",
            )}
            style={{
                width: toolbarRef.current?.offsetWidth,
                height: toolbarRef.current?.offsetHeight,
                top: initialPosition.y,
                left: initialPosition.x,
            }}
        />
    );

    return (
        <>
            <Draggable
                handle=".handle"
                position={position}
                defaultPosition={initialPosition}
                bounds={bounds}
                onStart={(_e, data) => handleStart(data)}
                onStop={(_e, data) => handlePosition(data)}
                onDrag={(_e, data) => handleOnDrag(data)}
            >
                <div
                    className={cn(
                        "absolute z-admin-toolbar flex items-center divide-gray-300 rounded-xl border bg-white py-1.5 shadow",
                        !isCollapsed && "divide-x",
                        isCollapsed && "opacity-60 hover:opacity-100",
                    )}
                    ref={toolbarRef}
                >
                    <CustomersDropdown className={cn("px-1.5", isCollapsed && "hidden")} />
                    <ToolbarLinks className={cn("px-1.5", isCollapsed && "hidden")} />

                    <div className="flex items-center gap-x-1.5 px-1.5">
                        <Button variant="ghost" size="icon" onClick={handleCollapsing}>
                            {isCollapsed ? <Expand className="h-5 w-5" /> : <Shrink className="h-5 w-5" />}
                        </Button>
                        <GripVertical
                            onDoubleClick={resetPositionToInitial}
                            className="handle h-5 w-5 cursor-grab active:cursor-grabbing"
                        />
                    </div>
                </div>
            </Draggable>

            {initialPosition && renderHighlightedPosition()}
        </>
    );
};
