import { ID } from "~/types/general.types";
import { authOptions } from "~/utils/auth-options.utils";
import { getServerSession } from "next-auth/next";
import qs from "qs";
import { ServiceRecordInput } from "~/generated-types";

const BASE_URL = "/api/customers";

export const getCustomerById = async (customerId: ID, sessionJwt?: string) => {
    let session;

    if (!sessionJwt) {
        session = await getServerSession(authOptions);
    }

    const query = qs.stringify(
        {
            fields: ["*"],
            populate: ["platform_logo"],
        },
        { encode: false },
    );

    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}/${customerId}?${query}`, {
        headers: {
            Authorization: `Bearer ${sessionJwt ?? session?.jwt}`,
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch customer data");
    }

    return await response.json();
};

export const getBasicCustomersList = async (sessionJwt: string) => {
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}?fields[0]=id&fields[1]=name&pagination[limit]=99999&sort=name:asc`,
        {
            headers: {
                Authorization: `Bearer ${sessionJwt}`,
            },
        },
    );

    if (!response.ok) {
        throw new Error("Failed to fetch customers data");
    }

    return await response.json();
};

export const updateCustomer = async (customerId: ID, body: ServiceRecordInput, sessionJwt: string) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}/${customerId}`, {
        headers: {
            Authorization: `Bearer ${sessionJwt}`,
            "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({ data: body }),
    });

    if (!response.ok) {
        throw new Error("Failed to update the customer data");
    }

    return await response.json();
};
