import { z } from "zod";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";
import { idSchema } from "~/app/api/index";

const commandCenterSavedMessageSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    name: z.string().min(1),
    body: z.string().min(1),
    user_id: z.number().int().min(1),
    customer_id: z.number().int().min(1),
    private: z.boolean(),
    description: z.string().optional(),
    supported_device_models: z.array(z.number().int()),
});

const commandCenterSavedMessageCreateDto = z.object({
    name: z.string().min(1),
    body: z.string().min(1),
    user_id: z.number().int().min(1),
    customer_id: z.string().min(1),
    private: z.boolean(),
    description: z.string().optional(),
    supported_device_models: z.array(z.number().int()),
});

const commandCenterSavedMessageUpdateDto = commandCenterSavedMessageCreateDto.partial();

export type CommandCenterSavedMessage = z.infer<typeof commandCenterSavedMessageSchema>;
export type CommandCenterSavedMessageCreateDto = z.infer<typeof commandCenterSavedMessageCreateDto>;

const ENDPOINT = "command-center-saved-messages";
export const commandCenterSavedMessagesRouter = createRouter(ENDPOINT);

export const commandCenterSavedMessages = {
    list: commandCenterSavedMessagesRouter.query(async (options) => {
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<CommandCenterSavedMessage[]>>(ENDPOINT, {
            params: options?.params,
        });
        return data;
    }),

    create: commandCenterSavedMessagesRouter.mutation<CommandCenterSavedMessageCreateDto, CommandCenterSavedMessage>(
        async (options) => {
            const parsed = commandCenterSavedMessageCreateDto.parse(options?.input);
            const client = await getApiClient().withAuth();
            const { data } = await client.post<RawApiResponse<CommandCenterSavedMessage>>(ENDPOINT, { data: parsed });
            return data;
        },
    ),

    update: commandCenterSavedMessagesRouter.mutation(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const parsedInput = commandCenterSavedMessageUpdateDto.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.put<RawApiResponse<CommandCenterSavedMessage>>(`${ENDPOINT}/${parsedId.id}`, {
            data: parsedInput,
        });
        return data;
    }),

    delete: commandCenterSavedMessagesRouter.mutation(async (options) => {
        const parsed = idSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.delete<RawApiResponse<CommandCenterSavedMessage>>(`${ENDPOINT}/${parsed.id}`);
        return data;
    }),
} as const;
