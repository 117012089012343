import { idSchema } from "~/app/api";
import { Alarm, alarmUpdateDto } from "~/app/api/alarms/schemas";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";

const ENDPOINT = "/alarms";
const alarmsRouter = createRouter(ENDPOINT);

export const alarms = {
    list: alarmsRouter.query<Alarm[]>(async (options) => {
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<Alarm[]>>(ENDPOINT, {
            params: options?.params,
        });
        return data;
    }),
    one: alarmsRouter.query<Alarm>(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<Alarm>>(`${ENDPOINT}/${parsedId.id}`, {
            params: options?.params,
        });
        return data;
    }),
    update: alarmsRouter.mutation(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const parsedInput = alarmUpdateDto.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.put<RawApiResponse<Alarm>>(`${ENDPOINT}/${parsedId.id}`, {
            data: parsedInput,
        });
        return data;
    }),
    delete: alarmsRouter.mutation(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.delete<RawApiResponse<Alarm>>(`${ENDPOINT}/${parsedId.id}`);
        return data;
    }),
} as const;
