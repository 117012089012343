import { api } from "~/app/api";
import { CustomerAppearance } from "~/app/api/customers";

interface Result {
    data: CustomerAppearance | undefined;
    isLoading: boolean;
    error: Error | null;
}

export const useCustomerAppearance = (): Result => {
    const domain = process.env.NODE_ENV === "production" ? window.location.host : process.env.NEXT_PUBLIC_DEV_SUBDOMAIN;

    if (!domain || domain === process.env.NEXT_PUBLIC_DEFAULT_DOMAIN) {
        return { data: undefined, isLoading: false, error: null };
    }

    const { data, isLoading, error } = api.customers.appearance.useQuery({
        input: { domain },
        keepNestedData: true,
    });

    return { data: data?.data, isLoading, error };
};
