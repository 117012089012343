import { z } from "zod";
import { getApiClient } from "~/lib/api/api-client";
import { createRouter } from "~/lib/api/create-router";
import { RawApiResponse } from "~/lib/api/types";

const commandCenterThreadSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    customer_id: z.number().int().min(1),
    messages: z.any().array(),
    user: z.any(),
    device: z.any(),
    status: z.enum(["scheduled", "outbox", "sent", "received"]).default("scheduled"),
});

const commandCenterThreadCreateDto = z.object({
    user: z.number().int().min(1),
    device: z.number().int().min(1),
    customer_id: z.string().min(1),
    status: z.enum(["scheduled", "outbox", "sent", "received"]),
});

export type CommandCenterThread = z.infer<typeof commandCenterThreadSchema>;
export type CreateCommandCenterThreadDto = z.infer<typeof commandCenterThreadCreateDto>;

const ENDPOINT = "command-center-threads";
export const commandCenterThreadsRouter = createRouter(ENDPOINT);

export const commandCenterThreads = {
    list: commandCenterThreadsRouter.query<CommandCenterThread[]>(async (options) => {
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<CommandCenterThread[]>>(ENDPOINT, {
            params: options?.params,
        });
        return data;
    }),

    create: commandCenterThreadsRouter.mutation<CreateCommandCenterThreadDto, CommandCenterThread>(async (options) => {
        const parsed = commandCenterThreadCreateDto.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.post<RawApiResponse<CommandCenterThread>>(ENDPOINT, { data: parsed });
        return data;
    }),
} as const;
