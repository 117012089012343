import { createRouter } from "~/lib/api/create-router";
import { getApiClient } from "~/lib/api/api-client";
import { RawApiResponse } from "~/lib/api/types";
import { Customer, CustomerAppearance, domainSchema } from "~/app/api/customers";
import { idSchema } from "~/app/api";

const ENDPOINT = "/customers";
export const customerRouter = createRouter(ENDPOINT);

export const customers = {
    one: customerRouter.query<Customer>(async (options) => {
        const parsedId = idSchema.parse(options?.input);
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<Customer>>(`${ENDPOINT}/${parsedId.id}`, {
            params: options?.params,
        });

        return data;
    }),
    appearance: customerRouter.query<CustomerAppearance>(async (options) => {
        const parsedDomain = domainSchema.parse(options?.input);
        const client = getApiClient().withoutAuth();
        const { data } = await client.get<RawApiResponse<CustomerAppearance>>(
            `${ENDPOINT}/appearance/${parsedDomain.domain}`,
            {
                params: options?.params,
            },
        );

        return data;
    }),
} as const;
