import { z } from "zod";

const alarmType = z.enum(["unplug_detection", "last_connection", "connected_beacons"]);

export const alarmSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    name: z.string(),
    type: alarmType,
    configuration: z.any(),
    triggered_message: z.string().optional(),
    resolved_message: z.string().optional(),
    receivers: z.any(),
    private: z.boolean(),
    paused: z.boolean(),
    user: z.any().optional(),
    units: z.object({ data: z.array(z.any()) }).optional(),
    beacons: z.object({ data: z.array(z.any()) }).optional(),
});

export const alarmCreateDto = z.object({
    name: z.string().min(1),
    type: alarmType,
    configuration: z.any(),
    triggered_message: z.string().optional(),
    resolved_message: z.string().optional(),
    receivers: z.any(),
    private: z.boolean().optional(),
    paused: z.boolean().optional(),
    units: z.array(z.string()).optional(),
    beacons: z.array(z.string()).optional(),
});

export const alarmUpdateDto = alarmCreateDto.partial();

export type Alarm = z.infer<typeof alarmSchema>;
export type AlarmCreateDto = z.infer<typeof alarmCreateDto>;
export type AlarmUpdateDto = z.infer<typeof alarmUpdateDto>;
