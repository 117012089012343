import { getServerSession } from "next-auth";
import { authOptions } from "~/utils/auth-options.utils";
import { ID } from "~/types/general.types";
import qs from "qs";

const BASE_URL = "/api/users";

export const getCurrentUser = async () => {
    const session = await getServerSession(authOptions);

    const query = qs.stringify(
        {
            populate: {
                customer: {
                    fields: ["id", "name"],
                },
                avatar: true,
                user_group: {
                    populate: "*",
                },
            },
        },
        { encode: false },
    );

    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}/me?${query}`, {
        headers: {
            Authorization: `Bearer ${session?.jwt}`,
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch user data");
    }

    return await response.json();
};

export const getUserCustomerId = async () => {
    const session = await getServerSession(authOptions);

    const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}/me?fields[0]=id&fields[1]=admin&populate[customer][fields]=id`,
        {
            headers: {
                Authorization: `Bearer ${session?.jwt}`,
            },
        },
    );

    if (!response.ok) {
        throw new Error("Failed to fetch user data");
    }

    return await response.json();
};

export const updateSimulatedCustomerId = async ({
    userId,
    simulateCustomerId,
    sessionJwt,
}: {
    userId: ID;
    simulateCustomerId: ID;
    sessionJwt: string;
}) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}/${userId}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${sessionJwt}`,
            "content-type": "application/json",
        },
        body: JSON.stringify({ simulate_customer_id: simulateCustomerId }),
    });

    if (!response.ok) {
        throw new Error("Failed to update simulated customer id");
    }

    return await response.json();
};
