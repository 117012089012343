import { useMemo } from "react";

export interface HourlyData {
    hour: number;
    usage: number;
    sessions: any[];
}

const groupSessionsByHour = (sessionsData: any) => {
    const hourlyData = Array(24)
        .fill(0)
        .map((_, i) => ({
            hour: i,
            usage: 0,
            sessions: [] as any,
        }));

    sessionsData?.forEach((session: any) => {
        if (!session || (!session.attributes.start && !session.attributes.timestamp)) return;

        const hour = new Date(session.attributes.start || session.attributes.timestamp).getHours();
        hourlyData[hour].usage += parseFloat(session.attributes.value);
        hourlyData[hour].sessions.push(session);
    });

    return hourlyData;
};

export const useGroupHourlySessions = (sessionsData: any): HourlyData[] => {
    return useMemo(() => {
        return groupSessionsByHour(sessionsData);
    }, [sessionsData]);
};
