import { z } from "zod";

export const co2ReadingSchema = z.object({
    id: z.number().int().min(1),
    source: z.string(),
    start: z.string().date(),
    stop: z.string().date(),
    value: z.number(),
    device_id: z.number(),
    createdAt: z.string().date(),
    updatedAt: z.string().date(),
    customer_id: z.number().int().min(1),
});

export type Co2Reading = z.infer<typeof co2ReadingSchema>;
