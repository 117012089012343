import { z } from "zod";

export const reportSchema = z.object({
    id: z.number().int().min(1),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    customer: z.any().optional(),
});

export const reportCreateDtoSchema = z.object({
    advanced_days: z.array(z.any()),
    customer: z.number().int(),
    generation_day: z.string(),
    generation_time: z.string().time(),
    generation_type: z.string(),
    name: z.string(),
    private: z.boolean(),
    show_geo_in_pdf: z.boolean(),
    show_zero_data: z.boolean(),
    start: z.string().datetime(),
    start_time: z.string().time(),
    stop: z.string().datetime(),
    stop_time: z.string().time(),
    type: z.string(),
    units: z.array(z.number().int()),
    user: z.number().int(),
});

export const reportUpdateDtoSchema = reportCreateDtoSchema.partial();

export type Report = z.infer<typeof reportSchema>;
export type ReportCreateDto = z.infer<typeof reportCreateDtoSchema>;
export type ReportUpdateDto = z.infer<typeof reportUpdateDtoSchema>;
