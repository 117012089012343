import { createRouter } from "~/lib/api/create-router";
import { getApiClient } from "~/lib/api/api-client";
import { RawApiResponse } from "~/lib/api/types";
import { Co2Reading } from "~/app/api/co2";

export const co2Router = createRouter("/co2");

export const co2 = {
    list: co2Router.query<Co2Reading[]>(async (options) => {
        const client = await getApiClient().withAuth();
        const { data } = await client.get<RawApiResponse<Co2Reading[]>>(`/co2-readings`, {
            params: options?.params,
        });

        return data;
    }),
};
