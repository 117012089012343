import axios, { AxiosInstance } from "axios";
import { getServerSession } from "next-auth";
import { getSession } from "next-auth/react";
import { authOptions } from "~/utils/auth-options.utils";

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE + "/api/";

class ApiClient {
    private static instance: ApiClient;
    private readonly api: AxiosInstance;
    private readonly isServer: boolean;

    private constructor() {
        // Check if we're in a server context
        this.isServer = typeof window === "undefined";

        this.api = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    public static getInstance(): ApiClient {
        if (!ApiClient.instance) {
            ApiClient.instance = new ApiClient();
        }

        return ApiClient.instance;
    }

    private async getSession() {
        return this.isServer ? getServerSession(authOptions) : getSession();
    }

    public async withAuth(): Promise<AxiosInstance> {
        const session = await this.getSession();

        if (!session?.jwt) {
            throw new Error("No session found");
        }

        this.api.defaults.headers.common["Authorization"] = `Bearer ${session.jwt}`;
        return this.api;
    }

    public withoutAuth(): AxiosInstance {
        // Remove any existing Authorization header if present
        delete this.api.defaults.headers.common["Authorization"];
        return this.api;
    }
}

// Export a single helper function to get the client
export const getApiClient = () => ApiClient.getInstance();
