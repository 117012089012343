import React, { FC, useContext } from "react";
import { Link } from "~/i18n/routing";
import { useCustomerAppearance } from "~/hooks";
import { UserContext } from "~/context/User.context";
import { Skeleton } from "@ui/skeleton";

export const SidebarLogo: FC = () => {
    const {
        state: { data: userData },
    } = useContext(UserContext);
    const {
        data: customerAppearance,
        isLoading: isCustomerAppearanceLoading,
        error: customerAppearanceError,
    } = useCustomerAppearance();

    return (
        <Link href="/" className="mb-4">
            <div className="flex items-center gap-3">
                {!isCustomerAppearanceLoading || customerAppearanceError ? (
                    <>
                        <img
                            alt="SporingsGruppen Logo"
                            src={customerAppearance?.logoUrl ?? "/logo-square-color.svg"}
                            className="h-9 w-9 rounded-md"
                        />

                        {!userData.collapsedSidebar && (
                            <>
                                {customerAppearance ? (
                                    <span
                                        className="truncate font-bold"
                                        style={{ color: customerAppearance?.primaryColor }}
                                    >
                                        {customerAppearance?.name}
                                    </span>
                                ) : (
                                    <span>
                                        <span className="font-bold">Sporings</span>
                                        <span className="font-light">Gruppen</span>
                                    </span>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <Skeleton className="h-9 w-full" />
                )}
            </div>
        </Link>
    );
};
