import { z } from "zod";
import { alarms } from "~/app/api/alarms";
import { co2 } from "~/app/api/co2";
import { commandCenterMessages } from "~/app/api/command-center-message";
import { commandCenterSavedMessages } from "~/app/api/command-center-saved-messages";
import { commandCenterThreads } from "~/app/api/command-center-threads";
import { customers } from "~/app/api/customers";
import { deviceModels } from "~/app/api/device-models";
import { integrations } from "~/app/api/integrations";
import { reports } from "~/app/api/reports";
import { users } from "~/app/api/users";

export const api = {
    alarms,
    co2,
    commandCenterMessages,
    commandCenterSavedMessages,
    commandCenterThreads,
    customers,
    deviceModels,
    integrations,
    reports,
    users,
} as const;

export const idSchema = z.object({
    id: z.number().int().min(1),
});
